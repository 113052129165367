import React from 'react'
import { Modal } from 'react-bootstrap'
import './ModalTerminos.scss';

const ModalTerminos = ({lgShowModalTerminos, setLgShowModalTerminos}) => {
    return (
        <>
            {/* Modal Terminos y condiciones */}          
            <Modal                
                size="lg"
                show={lgShowModalTerminos}
                onHide={() => setLgShowModalTerminos(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="false"
                scrollable
               >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  <h4 className="title">Términos y Condiciones</h4>                    
                  </Modal.Title>
                </Modal.Header>
                
                <form>
                <Modal.Body>                
                <div className="contenedor-modal">                
                    <ol className="class-ol">
                    <li>
                        <strong> Aceptación y reconocimiento</strong>
                        <p>Al aceptar ser miembro de Cadem Online, usted estará de acuerdo con las condiciones que aparecen más abajo. Además, permitirá que nuestra empresa envíe a usted información vía correo electrónico con la dirección o Url de los sitios en Internet que contendrán nuestras encuestas, donde le solicitaremos su opinión.
                        </p>
                    </li>
                    <li>
                        <strong> Proceso de registro en Cadem Online</strong>
                        <p>
                            Para ser miembro de Cadem Online, se deberá llenar una encuesta de registro que contiene datos personales. Estos datos personales serán ocupados sólo con fines de clasificación estadística y para segmentación de los participantes. En ningún caso Cadem Online entregará esta información a terceros, ni se identificará con nombre y apellido a las personas inscritas en el Panel.                            
                        </p>
                        <p>Cadem Online se compromete a proteger la privacidad de sus usuarios y la confidencialidad de toda la información suministrada a través de formularios y encuestas. De este modo, garantizamos que ninguna persona ajena al usuario podrá tener acceso, utilizar o revelar su información personal.</p></li>
                    <li>
                        <strong> Veracidad de las respuestas</strong>
                        <p>Usted se compromete a entregar información veraz y actualizada tanto en la encuesta de registro como en las encuestas que sean enviadas a su correo electrónico. Cadem Online acepta la participación de una persona con un solo perfil para contestar, por lo que Usted no podrá crear dos cuentas con datos distintos.</p>
                        <p>Para corroborar la veracidad de las respuestas y el uso de un solo perfil por persona, Cadem Online realizará supervisiones de identidad y controles periódicos. Nuestra empresa se reserva el derecho de eliminar de sus registros a quienes se compruebe entreguen información falsa o que utilicen datos de otras personas para crear perfiles fraudulentos.</p>
                        <p>Cada participante deberá tener una cuenta única de correo electrónico, no permitiéndose que dos personas utilicen la misma cuenta para responder encuestas.</p></li>
                    <li>
                        <strong> Durante su participación en Cadem Online usted acepta lo siguiente:</strong>
                        <p>No suplantar a otra persona natural (viva o muerta) o persona jurídica.</p>
                        <p>No manipular identidades para confundir el origen de cualquier contenido transmitido a las actividades del Panel o de las encuestas enviadas.</p>
                        <p>No intentar emitir más de una opinión por encuesta o realizar cualquier actividad que pueda afectar el resultado obtenido a través de cualquier actividad de las encuestas online de Cadem.</p></li>
                    <li>
                        <strong> Término de la participación en Cadem Online</strong>
                        <p>Si usted no desea seguir siendo un panelista activo de Cadem Online, puede enviar un correo electrónico a la dirección que aparecerá en los correos que contienen las encuestas.</p>
                        <p>Usted acepta, además, que Cadem Online elimine su cuenta de registro en el Panel en caso de tener sospechas de fraude o inactividad en el Panel.</p></li>
                    <li>
                        <strong> Incentivos</strong>
                        <p>Cada encuesta podría tener asignado un premio o incentivo entre aquellos que contesten la encuesta. Estos premios e incentivos serán variables y dependerán del largo del cuestionario y del tipo de preguntas que tenga la encuesta. Cadem Online entregará de forma clara y precisa la información acerca del premio o incentivo que tenga cada encuesta. Del mismo modo, el equipo Cadem Online publicará en su página web, y en sus redes sociales, los participantes ganadores y se contactará vía mail o telefónicamente con los mismos.</p>
                        <p>Los premios serán entregados, tras presentación de Cédula de Identidad del ganador, en las oficinas de Cadem, cuyas dependencias se encuentran en Santiago de Chile, Avenida Nueva de Lyon 145, piso -1. Aquellos ganadores que sean de regiones y se vean imposibilitados de cobrar sus premios, Cadem se compromete a enviar el respectivo premio a la dirección enviada por el ganador. Antes de este envío, el ganador debe enviar a Cadem mail con su Cédula de Identidad escaneada.</p>
                        <p>Si el ganador, una vez cumplida la fecha estipulada para retirar el premio asignado, no se presenta en nuestras oficinas ni da un aviso correspondiente, Cadem Online procederá a realizar un nuevo sorteo y entregar el premio estipulado al nuevo ganador.</p></li>
                    <li>
                        <strong> Sobre las campañas</strong></li>
                        <p>Cadem Online realizará durante el año distintas campañas específicas para promover el registro y participación de la personas en sus estudios&nbsp;<a href="https://cademonline.cl/wp-content/themes/cademonline/images/terminos_y_condiciones_cademonline.pdf">(Ver Términos y Condiciones campañas Cadem Online)</a>.</p>
                        <p>Cadem Online entregará de forma clara y precisa la información acerca del premio o incentivo que tenga cada campaña. Los premios e incentivos serán variables y dependerán de los objetivos pres establecidos.
                        </p><li><strong> Resolución de Conflictos</strong>
                        <p>Estos Términos y Condiciones se regirán por las leyes de la República de Chile y cualquier dificultad que se suscite será de competencia de los Tribunales Ordinarios civiles de este país. Para todos los efectos legales derivados de estos “Términos y Condiciones”, las partes fijan domicilio especial en la ciudad de Santiago de Chile y se someten a la jurisdicción de sus Tribunales de Justicia.</p></li>
                    <li>
                        <strong> Modificación de términos y condiciones</strong>
                        <p>CADEM se reserva el derecho de modificar sus ‘Términos y Condiciones’ sin alterar su esencia, en el evento que ocurra un caso fortuito, o de fuerza mayor, o que no esté simplemente considerado en estos ‘términos y condiciones’, o de que a su solo juicio lo considere apropiado, y se obliga a comunicar tal modificación a los participantes, a través de su sitio web <a href="https://www.cademonline.cl">www.cademonline.cl</a>.</p></li>
                    </ol>                
                </div>                       
                  
                </Modal.Body>
                <Modal.Footer>                  
                  <button type="button" className="btn btn-danger"
                  onClick={() => setLgShowModalTerminos(false)}
                  >
                    Cerrar
                  </button>
                  {/* <button type="button" className="btn btn-primary"                    
                    >Crear
                  </button> */}

                </Modal.Footer>
                </form>
               
               </Modal>
        </>
    )
}

export default ModalTerminos
