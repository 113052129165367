import React, {useState, useEffect} from 'react';
import { deviceDetect, browserName, isMobile } from 'react-device-detect';
import { useQueryParam, StringParam } from 'use-query-params';

import axios from 'axios';
import { Modal } from 'react-bootstrap';

import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NProgress from 'nprogress'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'nprogress/nprogress.css'
// Import CSS
import './formulario-registro.css';
// Helpers
import Helpers from '../../helpers/index';
//import Recaptcha from 'react-google-invisible-recaptcha';
import ReCAPTCHA from "react-google-recaptcha";

//Cookies
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Hidden } from '@material-ui/core';
import { NonceProvider } from 'react-select';

///////////////////
import ModalTerminos  from './modal/ModalTerminos'


const { validate } = require('rut.js')
const helpers = new Helpers();


// Configuraciónes APis
const config = require('../../configuration/config')
const API_BASE_URL = config.API_BASE_URL;
const API_BASE_URL_COMUNIDAD_CADEM = config.API_BASE_URL_COMUNIDAD_CADEM;
const API_BASE_URL_ENCUESTAS_DESTACADAS = config.API_BASE_URL_ENCUESTAS_DESTACADAS;

const CssTextField = withStyles({    
    root: {
        '& .MuiInputBase-input': {
          color: '#fff', // Text color
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#fff8', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor: '#fff', // Solid underline on hover
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#fff', // Solid underline on focus
        },
      },
  })(TextField)
  const CssTextFieldSelect = withStyles({    
    root: {        
        // '&$focused $notchedOutline': {
        //     color: "#fff",
        //  },
        "& .MuiInputBase-input": {
        //    color: "#fff",
        //   background:"orangered"
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#fff8', // Semi-transparent underline
          },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#fff', // Solid underline on hover
          },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff', // Solid underline on focus
          },
          '& .MuiInput-underline:focus': {
            background: 'red', // Solid underline on focus
          },

        
      },
  })(TextField)

const useStyles = makeStyles({
    root: props => ({        
        color: 'white',
      }),
    textfieldcolor:{
        color:'white'
    },    
    buttonreturn: {        
      background: 'linear-gradient(45deg, #2355A4 30%, #2355A4 90%)',
      border: 0,
      borderRadius: 3,
    //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      margin:'0 25px',
      
    },
    button: {        
        background: 'linear-gradient(45deg, #7c3088 30%, #7c3088 90%)',
        border: 0,
        borderRadius: 3,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        margin:'0 25px',
        // "&.focused": {
        //     border: "2px solid red",
        //     '& .MuiOutlinedInput-notchedOutline': {
        //       border: 'none'
        //     }
        //   }
      },

    
  });

const FormularioRegistro = ( { history } ) => {

    const classes = useStyles();
    const [lgShowModalTerminos, setLgShowModalTerminos] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['YII_CSRF_TOKEN']);
    const recaptchaRef = React.useRef();
    //Hook State
    ////////////////////////////////////////////////////////////////////////////////////
    // const classes = useStyles();
    const [tag, setTag] = useQueryParam('tag', StringParam);
    const [sid, setSid] = useQueryParam('sid', StringParam);
    const [ rut ] = useQueryParam('rut', StringParam);

    const [blocking, setBlocking] = useState(false);
    const [activeTab, setActiveTab] = useState("1");    
    
    const [buttonName, setButtonName] = useState("Continuar");
    const [MESES, setMESES] = useState([
        { value: 1, label: 'Enero' }, 
        { value: 2, label: 'Febrero' }, 
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' }
    ]);
    const [compannias, setCompannias] = useState([]);
    const [formasPago, setFormasPago] = useState([]);
    const [REGIONES, setREGIONES] = useState([]);
    const [COMUNAS, setCOMUNAS] = useState([]);
    const [GENEROS, setGENEROS] = useState([])
    const [LIST_SOSTENEDOR, setLIST_SOSTENEDOR] = useState([
        { value: 1, label: 'SI' },
        { value: 2, label: 'NO' }
    ])
    const [LIST_ACTIVIDAD, setLIST_ACTIVIDAD] = useState([]);
    const [LIST_NIVEL_EDUCACIONAL, setLIST_NIVEL_EDUCACIONAL] = useState([]);
    const [LIST_OCUPACION, setLIST_OCUPACION] = useState([]);
    const [messageSent, setMessageSent] = useState(false);

    const [stateForm, setStateForm] = useState(
        {
            NOMBRES:'',
            APELLIDOS:'',
            RUT: rut || '',
            EMAIL: '',
            COD_REGION: '',
            NOMBRE_REGION: '',
            COD_COMUNA: '',
            NOMBRE_COMUNA: '',
            ANIO_NACIMIENTO:'',
            MES_NACIMIENTO:'',
            DIA_NACIMIENTO:'',            
            NUMERO_CELULAR:'',
            COD_GENERO: '',
            NOMBRE_GENERO: '',
            NOMBRE_GENERO_OTROS: '',
            COD_COMPANIA: '',
            NOMBRE_COMPANIA: '',
            COD_FORMA_PAGO: '',
            NOMBRE_FORMA_PAGO: '',
            SOSTENEDOR: '',
            COD_SOSTENEDOR: '',
            COD_ACTIVIDAD: '',
            NOMBRE_ACTIVIDAD: '',
            COD_NIVELEDUCACION: '',
            NOMBRE_NIVELEDUCACION: '',
            COD_OCUPACION: '',
            NOMBRE_OCUPACION: '',
            TERMINOS:'',
            // P1:'',            
            // P3:'',
            // P4:'',
            // P5:'',
            // P6:'',
            // P7:'',
            // P8:'',                      
            // comunas : [], 
            
            
            years:[],
            days:[],                                                 
            error: {},
            texto_actividad : '¿Cuál es tu actividad principal?',
            texto_educacional : '¿Cuál es el tu nivel educacional?',
            texto_ocupacion: '¿Cuál es tu trabajo u ocupación?',            
            tbotton:'Continuar',                      
            // typeNY:'',
  
        } 
    )

    //Hook useEffect
    useEffect(() => {   
        
        if(tag==="sitio"){
       
            document.querySelector('body').style.backgroundColor= "transparent";
            document.querySelector('body').style.backgroundImage= "none";
            
            let querySelectorContainerForm = document.querySelector('#container-form');
            console.log('querySelectorXXX', querySelectorContainerForm)
            querySelectorContainerForm.classList.replace("col-md-8", "col-md-12");
            
            // document.querySelector('body').contentWindow.document.querySelector("#container-form").remove('col-md-8');
            // document.querySelector('body').contentWindow.document.querySelector("#container-form").add('col-md-12');                      
        }   


        loadYears();
        loadRegion();
        loadGeneros();
        loadActividad();
        loadNivelEducacion();
        loadOcupacion();
        loadCompannias();
        loadFormasPago();
        // loadPrueba();
    },[]);

    useEffect(() => {
        
       if(tag==="sitio" && lgShowModalTerminos){        
           console.log('holaxxx')
        // fade modal-backdrop show
        let div =  document.querySelector('.modal-backdrop') 
        console.log('div1', div)
        
     
        setTimeout(() => {
            div.classList.remove("modal-backdrop") 
            console.log('div2', div)
        }, 1);
                        
        // let querySelectorContainerForm = document.querySelector('#container-form');
        // console.log('querySelectorXXX', querySelectorContainerForm)
        // querySelectorContainerForm.classList.replace("col-md-8", "col-md-12");
    }

    }, [lgShowModalTerminos])

    ////////////////////////////////////////////////////////////////////////////////////
    //Arrow function
    const loadYears = ()=>{ 
        axios.get(API_BASE_URL + '/services-getyears').then(response => {              
            setStateForm({
                ...stateForm,
                years:response.data.listYears

            });            
       });
    } 
    const loadDaysFromYear = (vyear)=>{
        if(vyear && stateForm.MES_NACIMIENTO){
            axios.get(`${API_BASE_URL}/services-getdaysofmonth/${vyear}/${stateForm.MES_NACIMIENTO}`).then(response => {
                setStateForm({
                    ...stateForm,
                    ANIO_NACIMIENTO:vyear,
                    DIA_NACIMIENTO:'',
                    MES_NACIMIENTO:'',
                    error: {...stateForm.error,ANIO_NACIMIENTO:''},
                    days:response.data.listDays

                });
            });
        }else{
            setStateForm({
                ...stateForm,
                ANIO_NACIMIENTO:vyear,
                DIA_NACIMIENTO:'',
                MES_NACIMIENTO:'',
                error: {...stateForm.error,ANIO_NACIMIENTO:''}
            });
        }
    }

    const loadDaysFronMonth = (vmes)=>{

        if(vmes && stateForm.ANIO_NACIMIENTO){
            axios.get(`${API_BASE_URL}/services-getdaysofmonth/${stateForm.ANIO_NACIMIENTO}/${vmes}`).then(response => {
                setStateForm({
                    ...stateForm,
                    MES_NACIMIENTO:vmes,                    
                    DIA_NACIMIENTO:'',                    
                    error: {...stateForm.error,MES_NACIMIENTO:''},
                    days:response.data.listDays
                });        
            });
        }else{
            setStateForm({
                ...stateForm,
                MES_NACIMIENTO: vmes,                
                DIA_NACIMIENTO:'', 
                error: {...stateForm.error,MES_NACIMIENTO:''}
            })
        }
    }
    
    const loadRegion = ()=>{ 
        axios.get(API_BASE_URL + '/services-getregion').then(response => {              
            setREGIONES(response.data.listRegion);
       });
    }   
    const loadComunas = (vRegionId)=>{
        if(vRegionId){
            axios.get(`${API_BASE_URL}/services-getcomunas/${vRegionId}`).then(response => {               
                setCOMUNAS(response.data.listComunas);
            });
        }
    }

    const loadGeneros = ()=>{
            axios.get(`${API_BASE_URL}/services-getgeneros`).then(response => {               
                setGENEROS(response.data.listGeneros);
            });
    }

    const loadActividad = ()=>{
        axios.get(`${API_BASE_URL}/services-actividad`).then(response => {               
            setLIST_ACTIVIDAD(response.data.listActividad);
        });
    }
    const loadNivelEducacion = ()=>{
        axios.get(`${API_BASE_URL}/services-niveleducacion`).then(response => {               
            setLIST_NIVEL_EDUCACIONAL(response.data.listNivelEducacion);
        });
    }
    const loadOcupacion = ()=>{
        axios.get(`${API_BASE_URL}/services-ocupaciones`).then(response => {               
            setLIST_OCUPACION(response.data.listOcupacion);
        });
    }  

    const loadCompannias = () => {
        axios.get(`${API_BASE_URL}/services-get-compannias`).then(resp => {
            const companias = resp.data.compannias ? resp.data.compannias : [];
            setCompannias(companias);
        });
    }

    const loadFormasPago = () => {
        axios.get(`${API_BASE_URL}/services-get-formaspago`).then(resp => {
            const formasPago = resp.data.formasPago ? resp.data.formasPago : [];
            setFormasPago(formasPago);
        });
    }
    
    // const loadPrueba = ()=>{
        
    //     axios.get(`https://api-calculo-gse-dot-cademonline.ue.r.appspot.com/service-calcular-gse/educacion/6/ocupacion/5`)
    //     .then(response => {             
    //         console.log('response.data', response.data.gse[0]);  
    //     })
    //     .catch(error => {                                                  
    //         console.log('response.data form');                          
    //     });
    // }
 

    const onChangeForm = (e) => {
        setStateForm({
            ...stateForm,
            [e.target.name] : e.target.value,
            error:{
                ...stateForm.error,[e.target.name]:''
            }
        });
    }

    
    const formatRut = async() =>{        
        let rutFormatiado = stateForm.RUT.length > 1 ? helpers.formateaRut(stateForm.RUT) : "";        
        setStateForm({
            ...stateForm,
            RUT:rutFormatiado
        });        
    }


    const handleChangeSelectYears = (e) => {
        let vyear = e.target.value;
        loadDaysFromYear(vyear);
    }

    const handleChangeSelectMonths= (e) => {
        let vmes = e.target.value;
        loadDaysFronMonth(vmes);               
    }

    const handleChangeSelectDays= (e) => {
        setStateForm({
            ...stateForm,
            DIA_NACIMIENTO:e.target.value,
            error: {...stateForm.error,DIA_NACIMIENTO:''}
        })
    }

    const handleChangeSelectMultiRegion = (e) => {        
        let REGION = REGIONES.filter(x=>x.value == e.target.value);        
        console.log('REGION',REGION);
        if(REGION.length>0){  
            let COD_REGION = REGION[0].value;          
            let NOMBRE_REGION = REGION[0].label;
            setStateForm({
                ...stateForm,
                COD_REGION:COD_REGION,
                NOMBRE_REGION:NOMBRE_REGION,
                COD_COMUNA:'',
                NOMBRE_COMUNA:'',
                error: {...stateForm.error,COD_REGION:''}
            })    
            loadComunas(COD_REGION); 
        }else{
            setStateForm({
                ...stateForm,
                COD_REGION:'',
                NOMBRE_REGION:'',
                COD_COMUNA:'',
                NOMBRE_COMUNA:'',
                error: {...stateForm.error,COD_REGION:''}
            })  
        }
    }

    const handleChangeSelectMultiComuna = (e) => {  
        let COMUNA = COMUNAS.filter(x=>x.value == e.target.value);        
        console.log('COMUNA',COMUNA);
        if(COMUNA.length>0){  
            let COD_COMUNA = COMUNA[0].value;          
            let NOMBRE_COMUNA = COMUNA[0].label;
            setStateForm({
                ...stateForm,
                COD_COMUNA:COD_COMUNA,
                NOMBRE_COMUNA:NOMBRE_COMUNA,
                error: {...stateForm.error,COD_COMUNA:''}
            })    
        }else{
            setStateForm({
                ...stateForm,
                COD_COMUNA:'',
                NOMBRE_COMUNA:'',
                error: {...stateForm.error,COD_COMUNA:''}
            }) 
        }
    }

    const handleChangeSelectGenero = (e) => {        
        let GENERO = GENEROS.filter(x=>x.value == e.target.value);
        console.log('GENERO',GENERO);
        if(GENERO.length>0){  
            let COD_GENERO = GENERO[0].value;          
            let NOMBRE_GENERO = GENERO[0].label;
            setStateForm({
                ...stateForm,
                COD_GENERO:COD_GENERO,
                NOMBRE_GENERO:NOMBRE_GENERO,
                error: {...stateForm.error,COD_GENERO:''}
            })    
        }else{
            setStateForm({
                ...stateForm,
                COD_GENERO:'',
                NOMBRE_GENERO:'',
                error: {...stateForm.error,COD_GENERO:''}
            })   
        }
    }

    // actualizar estados para compañia
    const handleChangeCompannia = ({ target }) => {
        const compania = compannias.filter(item => item.value == target.value);
        if(compania.length > 0){
            const COD_COMPANIA = compania[0].value;          
            const NOMBRE_COMPANIA = compania[0].label;
            setStateForm({
                ...stateForm,
                COD_COMPANIA,
                NOMBRE_COMPANIA,
                error: {...stateForm.error,COD_COMPANIA:''}
            })  
        }else{
            setStateForm({
                ...stateForm,
                COD_COMPANIA: '',
                NOMBRE_COMPANIA: '',
                error: {...stateForm.error,COD_COMPANIA:''}
            })  
        }
    }

    // actualizar estados para formas de pago
    const handleChangeFormaPago = ({ target }) => {
        const formaPago = formasPago.filter(item => item.value == target.value);
        if(formaPago.length > 0){
            const COD_FORMA_PAGO = formaPago[0].value;          
            const NOMBRE_FORMA_PAGO = formaPago[0].label;
            setStateForm({
                ...stateForm,
                COD_FORMA_PAGO,
                NOMBRE_FORMA_PAGO,
                error: {...stateForm.error,COD_FORMA_PAGO:''}
            }) 
        }else{
            setStateForm({
                ...stateForm,
                COD_FORMA_PAGO: '',
                NOMBRE_FORMA_PAGO: '',
                error: {...stateForm.error,COD_FORMA_PAGO:''}
            }) 
        }
    }

    const handleChangeSelectSostenedor= (e) => {
        let texto_actividad = '';
        let texto_educacional = '';
        let texto_ocupacion = '';

        if(e.target.value == 1)
        {
            texto_actividad = '¿Cuál es tu actividad principal?';
            texto_educacional = '¿Cuál es el tu nivel educacional?';
            texto_ocupacion =  '¿Cuál es tu trabajo u ocupación?';

        }
        else{
            texto_actividad = '¿Cuál es la actividad principal del sostenedor del hogar?';
            texto_educacional = '¿Cuál es el nivel educacional del sostenedor del hogar?';
            texto_ocupacion = '¿Cuál es el trabajo u ocupación del sostenedor del hogar?';
        }

        let itemSostenedor = LIST_SOSTENEDOR.find(x=>x.value == e.target.value);
        setStateForm({
            ...stateForm,
            COD_SOSTENEDOR: itemSostenedor.value,
            SOSTENEDOR: itemSostenedor.label,
            error: {...stateForm.error,SOSTENEDOR:''},
            texto_actividad,
            texto_educacional,
            texto_ocupacion
        })
        
    }

    const handleChangeSelectActividad= (e) => {
        let ACTIVIDAD = LIST_ACTIVIDAD.filter(x=>x.value == e.target.value);
        console.log('ACTIVIDAD',ACTIVIDAD);
        if(ACTIVIDAD.length>0){  
            let COD_ACTIVIDAD = ACTIVIDAD[0].value;          
            let NOMBRE_ACTIVIDAD = ACTIVIDAD[0].label;
            setStateForm({
                ...stateForm,
                COD_ACTIVIDAD:COD_ACTIVIDAD,
                NOMBRE_ACTIVIDAD:NOMBRE_ACTIVIDAD,
                error: {...stateForm.error,COD_ACTIVIDAD:''}
            })    
        }else{
            setStateForm({
                ...stateForm,
                COD_ACTIVIDAD:'',
                NOMBRE_ACTIVIDAD:'',
                error: {...stateForm.error,COD_ACTIVIDAD:''}
            })   
        }

        // setStateForm({
        //     ...stateForm,
        //     COD_ACTIVIDAD:e.target.value,
        //     error: {...stateForm.error,COD_ACTIVIDAD:''}
        // });
    }

    const handleChangeSelectNivelEducacional= (e) => {
        let NIVELEDUCACIONAL = LIST_NIVEL_EDUCACIONAL.filter(x=>x.value == e.target.value);
        console.log('NIVEL_EDUCACIONAL',NIVELEDUCACIONAL);
        if(NIVELEDUCACIONAL.length>0){  
            let COD_NIVELEDUCACION = NIVELEDUCACIONAL[0].value;          
            let NOMBRE_NIVELEDUCACION = NIVELEDUCACIONAL[0].label;
            setStateForm({
                ...stateForm,
                COD_NIVELEDUCACION:COD_NIVELEDUCACION,
                NOMBRE_NIVELEDUCACION:NOMBRE_NIVELEDUCACION,
                error: {...stateForm.error,COD_NIVELEDUCACION:''}
            })    
        }else{
            setStateForm({
                ...stateForm,
                COD_NIVELEDUCACION:'',
                NOMBRE_NIVELEDUCACION:'',
                error: {...stateForm.error,COD_NIVELEDUCACION:''}
            })   
        }        
        // setStateForm({
        //     ...stateForm,
        //     nivel_educacional:e.target.value,
        //     error: {...stateForm.error,nivel_educacional:''}
        // });          
    }
    
    const handleChangeSelectOcupacion = (e) => {
        let OCUPACION = LIST_OCUPACION.filter(x=>x.value == e.target.value);        
        if(OCUPACION.length>0){  
            let COD_OCUPACION = OCUPACION[0].value;          
            let NOMBRE_OCUPACION = OCUPACION[0].label;
            setStateForm({
                ...stateForm,
                COD_OCUPACION:COD_OCUPACION,
                NOMBRE_OCUPACION:NOMBRE_OCUPACION,
                error: {...stateForm.error,COD_OCUPACION:''}
            })    
        }else{
            setStateForm({
                ...stateForm,
                COD_OCUPACION:'',
                NOMBRE_OCUPACION:'',
                error: {...stateForm.error,COD_OCUPACION:''}
            })   
        }

        // setStateForm({
        //     ...stateForm,
        //     ocupacion:e.target.value,
        //     error: {...stateForm.error,ocupacion:''}
        // });
    }

    const handleChangeSelectTerminos = (e) => {
        let terminos= e.target.value
        if(terminos==""){
            terminos="on"
        }else{
            terminos=""
        }
         setStateForm({
            ...stateForm,
            TERMINOS:terminos,
            error: {...stateForm.error,TERMINOS:''}
        });
     }
     const continuar = async (e) => {       
        e.preventDefault();
        
        // if(activeTab=="1"){
        //     NProgress.done();             
        //     setActiveTab("2");
        //     setButtonName("Registrar");
        // }

        if(activeTab=="1"){ 
            await validarPrimeraPagina();
        }

        if(activeTab=="2"){
            validarSegundaPagina();
        }  

        // await axios.post(
        //     `https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`,
        //     {},
        //     {
        //       headers: {
        //         "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        //       },
        //     },
        //   );
             

        
    } 
    // const continuar = (e) => {       
    //     e.preventDefault();
    //     console.log("sendMessage");
    //     this.recaptcha.execute();


    //     if(activeTab=="1"){            
    //         validarPrimeraPagina();
    //     }
    //     if(activeTab=="2"){
    //         validarSegundaPagina();
    //     }         
    // } 

    const atras = (e) => {
        e.preventDefault(); 
        if (activeTab === "2"){
            setActiveTab("1");
            setButtonName("Continuar");            
        }
    }

    const validarPrimeraPagina = async ()=>{
        setBlocking(true) 
        var validador=0;                
        NProgress.start();   

        var errores=[];
        if (stateForm.NOMBRES.trim()=="" || stateForm.NOMBRES.length < 2){
            errores["NOMBRES"]="El campo nombre es requerido"
            validador=1
        }
        if (stateForm.APELLIDOS.trim()=="" || stateForm.APELLIDOS.length < 2){
            const apellidos="El campo apellido es requerido";
            errores["APELLIDOS"]=apellidos;
            validador=1
        }
        if (stateForm.RUT==""){
            const rut="El campo rut es requerido"
            errores["RUT"]=rut
            validador=1
        }else{
            if (!validate(stateForm.RUT) || stateForm.RUT.length <=6){
                const rut="El rut que ingresaste no es válido, por favor intenta nuevamente"
                errores["RUT"]=rut
                validador=1
            }
            else{
                let rut = helpers.getRutSinDV(stateForm.RUT);  
                let dv = helpers.getDV(stateForm.RUT);                
                await axios({
                    url: API_BASE_URL + '/services-validate-rut',
                    method: 'POST',
                    data: {
                    rut:rut,
                    dv:dv
                    }   
                },
                ).then(async(response) => {        
                    if(!response.data.ok){ 
                        console.log('response.data A'); 
                        const rut="Rut se encuentra registrado"                        
                        errores["RUT"]=rut
                        validador=1        
                    }else{
                        await axios({                        
                            url: API_BASE_URL_COMUNIDAD_CADEM + '/services-validate-rut', 
                            method: 'POST',
                            data: {
                            rut:rut,
                            dv:dv
                            }   
                        },
                        ).then((response) => {    
                            if(!response.data.ok){ 
                                console.log('response.data C1');
                                const rut="Rut se encuentra registrado"                        
                                errores["RUT"]=rut
                                validador=1        
                            }                                              
                            // setBlocking(false);                  
                        })
                        .catch(error => { 
                            console.log('error.response.data', error.response.data);
                            if(!error.response.data.ok){        
                                const rut="Rut se encuentra registrado"                        
                                errores["RUT"]=rut
                                validador=1        
                            } 
                            // setBlocking(false);
                        });
                    }
                    // setBlocking(false);                  
                })
                .catch(error => { 
                    console.log('response.data 2222');
                    console.log('error.response.data', error.response.data);
                    if(!error.response.data.ok){        
                        const rut="Rut se encuentra registrado"                        
                        errores["RUT"]=rut
                        validador=1        
                    } 
                    // setBlocking(false);
                });
            }
        }


        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (stateForm.EMAIL==""){
            const email="El campo email es requerido"
            errores["EMAIL"]=email
            validador=1
        }
        if(!regexEmail.test(stateForm.EMAIL)){
            const email="El formato ingresado no es valido"
            errores["EMAIL"]=email
            validador=1
        }else{
            var score;
            // setBlocking(true);
            // https://mailboxlayer.com/documentation#quality_score
            await axios.get('https://apilayer.net/api/check?access_key=c8efe4999966778ea67a24bdaa9a7cf6&email='+stateForm.EMAIL+'&smtp=1')
            .then(response =>{    
                // smtp=response.data.smtp_check;  
                score=response.data.score;  
                if(score < 0.33){
                    const email="El email que ingresaste no es válido, por favor intenta nuevamente"                    
                    errores["EMAIL"]=email
                    validador=1                    
                }
                // setBlocking(false);
            })
            .catch(error => {                                                              
                console.log('Error', error);
                // setBlocking(false);
            });
            if (score >= 0.33){
                let email = stateForm.EMAIL;                                  
                await axios({
                    url: API_BASE_URL + '/services-validate-email',
                    method: 'POST',
                    data: {
                    email:email,
                    }
                },
                ).then(async (response) => {            
                    if(!response.data.ok){        
                        const email="Email ya esta registrado"                        
                        errores["EMAIL"]=email
                        validador=1                        
                    }else{
                        await axios({
                            url: API_BASE_URL_COMUNIDAD_CADEM + '/services-validate-email',
                            method: 'POST',
                            data: {
                            email:email,
                            }
                        },
                        ).then((response) => {            
                            if(!response.data.ok){        
                                const email="Email ya esta registrado"                        
                                errores["EMAIL"]=email
                                validador=1                        
                            }                                     
                        })
                        .catch(error => {                                                              
                            console.log('Error', error);
                        });
                    }
                })
                .catch(error => {                                                              
                    console.log('Error', error);
                });

            }           
        }


        if (stateForm.ANIO_NACIMIENTO==""){
            const anio="El campo año es requerido"
            errores["ANIO_NACIMIENTO"]=anio
            validador=1
        }
        if (stateForm.MES_NACIMIENTO==""){
            const mes="El campo año es requerido"
            errores["MES_NACIMIENTO"]=mes
            validador=1
        }
        if (stateForm.DIA_NACIMIENTO==""){
            const dia="El campo dia es requerido"
            errores["DIA_NACIMIENTO"]=dia
            validador=1
        }
        if (stateForm.COD_REGION==""){
            const COD_REGION="El campo región es requerido"
            errores["COD_REGION"]=COD_REGION
            validador=1
        }
        if (stateForm.COD_COMUNA==""){
            const COD_COMUNA="El campo comuna es requerido"
            errores["COD_COMUNA"]=COD_COMUNA
            validador=1
        }
        if (stateForm.COD_GENERO==""){            
            errores["COD_GENERO"]="El campo género es requerido";
            validador=1;
        }

        if (stateForm.COD_COMPANIA==""){            
            errores["COD_COMPANIA"]="El campo compañia es requerido";
            validador=1;
        }
        if (stateForm.COD_FORMA_PAGO==""){            
            errores["COD_FORMA_PAGO"]="El campo forma de pago es requerido";
            validador=1;
        }

        if (stateForm.NUMERO_CELULAR.trim().length!=9){
            const celular="El largo del campo celular debe ser igual a 9"
            // const celular="El campo celular es requerido"
            errores["NUMERO_CELULAR"]=celular
            validador=1
        }
        if (stateForm.NUMERO_CELULAR.trim().charAt(0) != 9){
            const celular="El celular debe iniciar con 9"
            errores["NUMERO_CELULAR"]=celular
            validador=1
        }
        if (stateForm.NUMERO_CELULAR.trim() == ""){
            const celular="El campo celular es requerido"
            errores["NUMERO_CELULAR"]=celular
            validador=1
        }  
        
        if (isNaN(stateForm.NUMERO_CELULAR.trim())){
            const celular="El campo celular es incorrecto"
            errores["NUMERO_CELULAR"]=celular
            validador=1
        }
                
        setStateForm({
            ...stateForm,
            error: errores ,
            validador

        });

        NProgress.done(); 

        if (validador==0){
            setActiveTab("2");
            setButtonName("Registrar");            
        }
        setBlocking(false)
        
    }

    const validarSegundaPagina = async ()=>{
        var validador=0;
        NProgress.start();   
        var errores=[];
        
        if (stateForm.SOSTENEDOR==""){
            const sostenedor="El campo es requerido"
            errores["SOSTENEDOR"]=sostenedor
            validador=1
        }
        if (stateForm.COD_ACTIVIDAD==""){
            const actividad="El campo es requerido"
            errores["COD_ACTIVIDAD"]=actividad
            validador=1
        }
        if (stateForm.COD_NIVELEDUCACION==""){
            const nivel_educacional="El campo es requerido"
            errores["COD_NIVELEDUCACION"]=nivel_educacional
            validador=1
        }
        if (stateForm.COD_OCUPACION==""){
            const ocupacion="El campo es requerido"
            errores["COD_OCUPACION"]=ocupacion
            validador=1
        }
        if (stateForm.TERMINOS==""){
            const terminos="Debes aceptar los términios y condiciones"
            errores["TERMINOS"]=terminos
            validador=1
        }
        // this.setState({error:errores});
        setStateForm({
            ...stateForm,
            error: errores
        });

        NProgress.done(); 
        if (validador==0){
            setBlocking(true); 
            await SaveForm();
            setBlocking(false); 
            
        }
    }

    const SaveForm = async () =>{

        const token = await recaptchaRef.current.executeAsync();                      
        if(token != ""  && token != undefined && token ){                        
            const datosformulario = {...stateForm};
            let rut = helpers.getRutSinDV(datosformulario.RUT); 
            let dv = helpers.getDV(datosformulario.RUT); 
            datosformulario.RUT = rut 
            datosformulario.DV = dv;
    
            datosformulario.TERMINOS ="ACEPTADOS";
            datosformulario.ORIGEN = (tag != undefined) ? tag : "sitio";
    
            let infoDevice = deviceDetect();
            datosformulario.browserName = browserName;
            datosformulario.isMobile = isMobile; 
            datosformulario.browserFullVersion = infoDevice.browserFullVersion;
            datosformulario.browserMajorVersion = infoDevice.browserMajorVersion;
            datosformulario.engineName = infoDevice.engineName;
            datosformulario.engineVersion = infoDevice.engineVersion;
            datosformulario.osName = infoDevice.osName;
            datosformulario.osVersion = infoDevice.osVersion;
            datosformulario.userAgent = infoDevice.userAgent;
            datosformulario.token = token;
              
            delete datosformulario.years;
            delete datosformulario.days;                                                
            delete datosformulario.error;
            delete datosformulario.texto_actividad;
            delete datosformulario.texto_educacional;
            delete datosformulario.texto_ocupacion;
            delete datosformulario.tbotton;
            delete datosformulario.validador;
    
            // setBlocking(true); 
            let mail = datosformulario.EMAIL.trim().toLowerCase();
            await axios.get(`https://api-calculo-gse-ba7zkvym3a-ue.a.run.app/service-calcular-gse/educacion/${stateForm.COD_NIVELEDUCACION}/ocupacion/${stateForm.COD_OCUPACION}/mail/${mail}`)
            .then(response => {  
    
                datosformulario.COD_GSE =  response.data.gse[0].cod_gse;
                datosformulario.GSE =  response.data.gse[0].gse;
                // setBlocking(false);
            })
            .catch(error => {                  
                datosformulario.COD_GSE = "";
                datosformulario.GSE = ""
                // setBlocking(false);
            });  

            // setBlocking(true);
            await axios({
                url: API_BASE_URL + '/services-save-form-cademonline',
                method: 'POST',
                data: {
                    datosformulario: datosformulario,                
                }
              },
              ).then(async (response) => {
                
                if(response.data.ok){
                    console.log('response.data form', response.data);
                    if(tag && sid){
                        let rutBase64 = window.btoa(`${rut}-${dv}`);
                        await axios.get(`${API_BASE_URL_ENCUESTAS_DESTACADAS}/service-receive-data/rut/${rutBase64}/sid/${sid}`)
                        .then(response => {                                                       
                            let token = response.data.data.token;                                                        
                              history.push({
                                pathname: '/agradecimiento',
                                search: `?origen=encuestadestacada&sid=${sid}&token=${token}`
                                // state: { some: 'state' }
                              });                            
                        })
                        .catch(error => {
                            console.log('error api encuestas destacadas', error);
                            // setBlocking(false);
                        });
                                                                   
                    }else{
                        console.log('NO Entro Servicio Encuesta Destacada');                        
                        // removeCookie("YII_CSRF_TOKEN");

                        history.push({
                            pathname: '/agradecimiento',
                            search: '?origen=cademonline'
                            // state: { some: 'state' }
                          });
                    }
                }else{
                    Swal.fire('Oops...', response.data.mensaje , 'error');
                } 
                // setBlocking(false);                   
              })
              .catch(error => {                                                  
                console.log('response.data form', error);   
                // setBlocking(false);             
            });
    
        }


    }
    
    const onResolved = () => {        
        setMessageSent(true);        
        console.log(stateForm);
    }

    const OpenModalTerminos = () => {

        setLgShowModalTerminos(true)

    }
    ////////////////////////////////////////////////////////////////////////////////////     
    return (
        <>
        
        {
            (tag !=="sitio") && (
                <header className="header">
                <img src="img/logo-cadem-online.svg" alt="trillo logo" className="logo" />                
                <div className="iconoscadem">
                            <a href="https://www.instagram.com/cadem_online/?hl=es-la" target="_blank" rel="noreferrer"><img src="img/instagram.png" alt="Logo Instagram"  style={{ height: '40px' }} /></a>
                            <a href="https://es-la.facebook.com/panelcademonline" target="_blank" rel="noreferrer"><img src="img/facebook.png" alt="Logo Facebook"  style={{ height: '40px' }} /></a>                    
                    </div>    
                </header>
            )
        }        
            <main className={classes.root}>            
                <BlockUi tag="div" blocking={blocking}>
                    <br></br>
                    <div id="container-form" className="container-fluid col-md-8" >
                        <div className="title_container">
                            {
                                activeTab === "1"
                                ? <h4>DATOS PERSONALES</h4>
                                : <h4>ACTIVIDAD</h4>
                            }                        
                        </div>
                        <Row>
                            <Nav tabs className="col-md-12 tab" style={{display:'none'}}>
                                <NavItem className="col-md-6 tab text-center">
                                    <NavLink className={activeTab === "1" ? "active" : ""}>
                                        DATOS PERSONALES
                                    </NavLink>
                                </NavItem >
                                <NavItem className="col-md-6 tab text-center">
                                    <NavLink className={activeTab === "2" ? "active" : ""}>
                                        ACTIVIDAD
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            {/* /////////////////////// */}
                            {/* Formulario */}
                            <div className="col-xs-12 col-md-12 fondo">
                                <form action="" name="actualizacion" autoComplete="false" className="formClass">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1" className="text-center" style={{marginTop:25}}>
                                            <fieldset>
                                                <div className="form-group row mda-form-control-dark">
                                                    <Col md={ 4 }>
                                                    <CssTextField                                              
                                                            id="RUT"
                                                            name="RUT"
                                                            label="Rut"
                                                            autoComplete="off"
                                                            value={stateForm.RUT}
                                                            onChange={onChangeForm}
                                                            onBlur={formatRut}                                               
                                                            type="text"                                                        
                                                            // className="outlined-full-width"
                                                            margin="normal"                                                        
                                                            InputProps={{                                                            
                                                                classes: {
                                                                    input: classes.textfieldcolor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    input: classes.textfieldcolor
                                                                }
                                                            }}
                                                            fullWidth
                                                        
                                                        />
                                                        {stateForm.error.RUT ? <span className="error-input">{stateForm.error.RUT}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                    <CssTextField
                                                            id="NOMBRES"
                                                            name="NOMBRES"
                                                            label="Nombre(s)"
                                                            autoComplete="off"
                                                            value={stateForm.NOMBRES}
                                                            onChange={ onChangeForm }
                                                            type="text"
                                                            className="outlined-full-width"
                                                            margin="normal"
                                                            fullWidth
                                                        
                                                        />
                                                        {stateForm.error.NOMBRES ? <span className="error-input">{stateForm.error.NOMBRES}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                    <CssTextField
                                                            id="APELLIDOS"
                                                            name="APELLIDOS"
                                                            label="Apellidos"
                                                            autoComplete="off"
                                                            value= {stateForm.APELLIDOS}
                                                            onChange={onChangeForm}
                                                            type="text"
                                                            className="outlined-full-width"
                                                            margin="normal"
                                                            fullWidth
                                                        
                                                        />
                                                        {stateForm.error.APELLIDOS ? <span className="error-input">{stateForm.error.APELLIDOS}</span> :  ""}
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 6 }>
                                                    <CssTextField
                                                            id="EMAIL"
                                                            name="EMAIL"
                                                            label="Email"
                                                            autoComplete="off"
                                                            value={stateForm.EMAIL}
                                                            onChange={onChangeForm}
                                                            type="text"
                                                            className="outlined-full-width"
                                                            margin="normal"
                                                            fullWidth
                                                        
                                                        />
                                                        {stateForm.error.EMAIL ? <span className="error-input">{stateForm.error.EMAIL}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 6 }> 
                                                        <CssTextFieldSelect
                                                            id="COD_GENERO"
                                                            name="COD_GENERO"
                                                            select
                                                            label="Género"
                                                            value={stateForm.COD_GENERO}
                                                            onChange={handleChangeSelectGenero}
                                                            SelectProps={{
                                                            native: true
                                                            }}
                                                            // InputProps={{
                                                            //     classes: {                                                            
                                                            //         focused: classes.focused,                                                            
                                                            //     }
                                                            //  }}                                                        
                                                            margin="normal"
                                                            fullWidth
                                                        >
                                                            <option key="0" value="">
                                                            </option>
                                                            {GENEROS.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                            ))}
                                                        </CssTextFieldSelect>
                                                        {stateForm.error.COD_GENERO ? <span className="error">{stateForm.error.COD_GENERO}</span> :  ""}
                                                        {
                                                            stateForm.COD_GENERO === 3 && (
                                                                <CssTextField
                                                                    id="NOMBRE_GENERO_OTROS"
                                                                    name="NOMBRE_GENERO_OTROS"
                                                                    autoComplete="off"
                                                                    value={stateForm.NOMBRE_GENERO_OTROS}
                                                                    onChange={onChangeForm}
                                                                    placeholder="Especifica"
                                                                    type="text"
                                                                    className="outlined-full-width"
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            )
                                                        }
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                                id="ANIO_NACIMIENTO"
                                                                name="ANIO_NACIMIENTO"
                                                                select
                                                                label="Año Nacimiento"
                                                                value={stateForm.ANIO_NACIMIENTO}
                                                                onChange={handleChangeSelectYears}                                                    
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >                                                    
                                                                <option key="" value="">
                                                                </option>
                                                                {stateForm.years.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))} 
                                                            </CssTextField>  
                                                            {stateForm.error.ANIO_NACIMIENTO ? <span className="error-input">El campo año es requerido</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                                id="MES_NACIMIENTO"
                                                                name="MES_NACIMIENTO"
                                                                select
                                                                label="Mes Nacimiento"
                                                                value={stateForm.MES_NACIMIENTO}
                                                                onChange={handleChangeSelectMonths}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                <option key="" value="">
                                                                </option>
                                                                {MESES.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}                                                
                                                        </CssTextField>
                                                        {stateForm.error.MES_NACIMIENTO ? <span className="error-input">{stateForm.error.MES_NACIMIENTO}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                                id="DIA_NACIMIENTO"
                                                                name="DIA_NACIMIENTO"
                                                                select
                                                                label="Día Nacimiento"
                                                                value={stateForm.DIA_NACIMIENTO}
                                                                onChange={handleChangeSelectDays}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                <option key="" value="">
                                                                </option>
                                                                {stateForm.days.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                        </CssTextField>
                                                        {stateForm.error.DIA_NACIMIENTO ? <span className="error">{stateForm.error.DIA_NACIMIENTO}</span> :  ""}
                                                    </Col>
                                                </div>
                                            </fieldset>    
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 6 }>
                                                        <CssTextField
                                                            id="COD_REGION"
                                                            name="COD_REGION"
                                                            select
                                                            label="Región"
                                                            value={stateForm.COD_REGION}
                                                            onChange={handleChangeSelectMultiRegion}
                                                            SelectProps={{
                                                            native: true
                                                            }}
                                                            margin="normal"
                                                            fullWidth
                                                        >
                                                            <option key="" value="">
                                                            </option>
                                                            {REGIONES.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                            ))}
                                                            </CssTextField>
                                                    {stateForm.error.COD_REGION ? <span className="error">{stateForm.error.COD_REGION}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 6 }>
                                                    <CssTextField
                                                            id="COD_COMUNA"
                                                            name="COD_COMUNA"
                                                            select
                                                            label="Comuna"
                                                            value={stateForm.COD_COMUNA}
                                                            onChange={handleChangeSelectMultiComuna}
                                                            SelectProps={{
                                                            native: true
                                                            }}
                                                            margin="normal"
                                                            fullWidth
                                                        >
                                                            <option key="" value="">
                                                            </option>
                                                            {COMUNAS.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                            ))}
                                                            </CssTextField>
                                                        {stateForm.error.COD_COMUNA ? <span className="error">{stateForm.error.COD_COMUNA}</span> :  ""}
                                                    </Col>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                                id="NUMERO_CELULAR"
                                                                name="NUMERO_CELULAR"
                                                                label="Celular"
                                                                autoComplete="off"
                                                                value={stateForm.NUMERO_CELULAR}
                                                                onChange={ onChangeForm }
                                                                type="text"
                                                                className="outlined-full-width"
                                                                margin="normal"
                                                                placeholder="ejemplo: 912346754"
                                                                fullWidth                                                
                                                            />
                                                        {stateForm.error.NUMERO_CELULAR ? <span className="error-input">{stateForm.error.NUMERO_CELULAR}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                            select
                                                            fullWidth
                                                            margin="normal"
                                                            label="Compañia"
                                                            id="COD_COMPANIA"
                                                            name="COD_COMPANIA"
                                                            value={stateForm.COD_COMPANIA}
                                                            SelectProps={{ native: true }}
                                                            onChange={handleChangeCompannia}
                                                        >
                                                            <option key="" value=""></option>
                                                            {
                                                                compannias.map(option => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </CssTextField>
                                                        {stateForm.error.COD_COMPANIA ? <span className="error">{stateForm.error.COD_COMPANIA}</span> :  ""}
                                                    </Col>
                                                    <Col md={ 4 }>
                                                        <CssTextField
                                                            select
                                                            fullWidth
                                                            margin="normal"
                                                            label="¿Tu celular lo tienes con ...?"
                                                            id="COD_FORMA_PAGO"
                                                            name="COD_FORMA_PAGO"
                                                            SelectProps={{ native: true }}
                                                            onChange={handleChangeFormaPago}
                                                        >
                                                            <option key="" value=""></option>
                                                            {
                                                                formasPago.map(option => (
                                                                    <option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </CssTextField>
                                                        {stateForm.error.COD_FORMA_PAGO ? <span className="error">{stateForm.error.COD_FORMA_PAGO}</span> :  ""}
                                                    </Col>
                                                </div>
                                            </fieldset> 
                                        </TabPane>
                                        
                                        {/* <TabPane tabId="2" className="text-center"> */}
                                        <TabPane tabId="2" className="text-center" style={{marginTop:25}}>
                                            <fieldset>
                                                {/* <div className="form-group row"> */}
                                                <div className="form-group row mda-form-control-dark">
                                                    <Col md={ 12 }>
                                                        <CssTextField
                                                                id="COD_SOSTENEDOR"
                                                                name="COD_SOSTENEDOR"
                                                                select
                                                                label="¿Es usted la persona que aporta el ingreso principal del hogar?"
                                                                value={stateForm.COD_SOSTENEDOR}
                                                                onChange={handleChangeSelectSostenedor}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                <option key="" value="">
                                                                </option>
                                                                {LIST_SOSTENEDOR.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                            </CssTextField>                                            
                                                    </Col>
                                                    {stateForm.error.SOSTENEDOR ? <span className="error text-left" style={{paddingLeft:10}}>{stateForm.error.SOSTENEDOR}</span> :  ""}                                                                      
                                                </div>
                                            </fieldset>

                                            <fieldset>
                                                <div className="form-group row">
                                                <Col md={ 12 }>
                                                        <CssTextField
                                                                id="COD_ACTIVIDAD"
                                                                name="COD_ACTIVIDAD"
                                                                select
                                                                label={stateForm.texto_actividad}
                                                                value={stateForm.COD_ACTIVIDAD}
                                                                onChange={handleChangeSelectActividad}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                <option key="" value="">
                                                                </option>
                                                                {LIST_ACTIVIDAD.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                            </CssTextField>                                                
                                                    </Col>
                                                    {stateForm.error.COD_ACTIVIDAD ? <span className="error text-left" style={{paddingLeft:10}} >{stateForm.error.COD_ACTIVIDAD}</span> :  ""}
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 12 }>
                                                    <CssTextField
                                                                id="COD_NIVELEDUCACION"
                                                                name="COD_NIVELEDUCACION"
                                                                select
                                                                label={stateForm.texto_educacional}
                                                                value={stateForm.COD_NIVELEDUCACION}
                                                                onChange={handleChangeSelectNivelEducacional}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                <option key="" value="">
                                                                </option>
                                                                {LIST_NIVEL_EDUCACIONAL.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                            </CssTextField>                                                
                                                    </Col>
                                                    {stateForm.error.COD_NIVELEDUCACION ? <span className="error text-left" style={{paddingLeft:10}} >{stateForm.error.COD_NIVELEDUCACION}</span> :  ""}
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row">
                                                    <Col md={ 12 }>
                                                    <CssTextField
                                                                id="COD_OCUPACION"
                                                                name="COD_OCUPACION"
                                                                select
                                                                label={stateForm.texto_ocupacion}
                                                                value={stateForm.COD_OCUPACION}
                                                                onChange={handleChangeSelectOcupacion}
                                                                SelectProps={{
                                                                native: true
                                                                }}
                                                                margin="normal"
                                                                fullWidth
                                                            >
                                                                    <option key="" value="">
                                                                </option>
                                                                {LIST_OCUPACION.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                            </CssTextField>                                                                                                    
                                                    </Col>
                                                    {stateForm.error.COD_OCUPACION ? <span className="error text-left" style={{paddingLeft:10}}>{stateForm.error.COD_OCUPACION}</span> :  ""}
                                                    </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="form-group row terminos">
                                                <Col md={ 12 }>
                                                {/* <a href="https://cademonline.cl/#color-7"  */}
                                                {/* {
                                                    tag==="sitio" 
                                                    ? (
                                                    <>
                                                    
                                                    <a href="http://localhost:3001/#color-7" 
                                                    className="hterminos" target="_blank">
                                                    Términos y Condiciones                                                
                                                    </a> &nbsp; 
                                                    </> 
                                                    )
                                                    :(
                                                    <>
                                                    <a
                                                    className="hterminos" onClick= { () => setLgShowModalTerminos(true)} >
                                                    Términos y Condiciones                                                
                                                    </a> &nbsp; 
                                                    </>
                                                    )
                                                } */}
                                                    <a
                                                    className="hterminos" href="https://cademonline.cl/terminos-y-condiciones" target="_blank">
                                                    Términos y Condiciones                                                
                                                    </a> &nbsp;
                                                                                          

                                                </Col>
                                                <Col>
                                                <FormControlLabel
                                                    // value="on"
                                                    id="TERMINOS"
                                                    name="TERMINOS"
                                                    value={stateForm.TERMINOS}                                            
                                                    onChange={handleChangeSelectTerminos}
                                                    control={<Checkbox color="primary" />}
                                                    label="Ok, Acepto."
                                                    labelPlacement="end"
                                                    />                                                  
                                                </Col>
                                            
                                                </div>
                                                <div className="form-group row terminos">
                                                <Col>
                                                {stateForm.error.TERMINOS ? <span className="error">{stateForm.error.TERMINOS}</span> :  ""}
                                                </Col>
                                                </div>
                                            </fieldset>                                   
                                        </TabPane>
                                    </TabContent>
                                    <div className="cardbox-footer text-center mb-4" >
                                    {/* className="cardbox-footer text-center mb-4" */}
                                        {/* {activeTab==2?<button onClick={atras} className="btn btn-azul boton">Volver</button>:""} */}
                                        {/* <button onClick={continuar} className="btn boton">{buttonName}</button> */}

                                        {activeTab==2?<button onClick={atras} className={classes.buttonreturn}>Volver</button>:""}
                                        <button onClick={continuar} className={classes.button}>{buttonName}</button>
                                        
                                    </div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey="6LcJK40aAAAAAJTEXIY3QP5TtU7T1gqx16xdaEHU"
                                        // sitekey="6Le4oqYZAAAAAKxfIHOyMVGj2iNigpIO9o_PnSeq"                                    
                                        // onResolved={ onResolved } 
                                    />
                                </form>
                            </div>
                        </Row>
                    </div>

            {/* Modal Terminos y condiciones */}         
            <ModalTerminos
                lgShowModalTerminos = {lgShowModalTerminos}
                setLgShowModalTerminos = {setLgShowModalTerminos}
            />
            </BlockUi>
            </main>            
        
        </>
        
        
    );
    
}
 
export default FormularioRegistro;