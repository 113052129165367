//Localhost
// export const API_BASE_URL = "http://localhost:5001"; // Desarrollo
// export const API_BASE_URL_COMUNIDAD_CADEM = "http://localhost:5000"; // Desarrollo
// export const API_BASE_URL_ENCUESTAS_DESTACADAS = "http://localhost:5010"; // Desarrollo

//Producción
// export const API_BASE_URL = "https://api-cadem-online-dot-cademonline.ue.r.appspot.com"; // Produccion
// export const API_BASE_URL_COMUNIDAD_CADEM = "https://api-cadem-comunidad-dot-comunidad-cadem.appspot.com"; // Produccion
// export const API_BASE_URL_ENCUESTAS_DESTACADAS = "https://api-encuestas-destacadas-dot-cademonline.ue.r.appspot.com"; // Produccion

//Producción Cloud Run
// export const API_BASE_URL = "https://backend-formulario-cademonline-ba7zkvym3a-ue.a.run.app"; // Produccion
// export const API_BASE_URL_COMUNIDAD_CADEM = "https://backend-form-comunidad-cadem-pyxwmvpacq-ue.a.run.app"; // Produccion
// export const API_BASE_URL_ENCUESTAS_DESTACADAS = "https://backend-encuestas-destacadas-ba7zkvym3a-ue.a.run.app"; // Produccion

export const SIZE_FILE =  30000000;
export const API_BASE_URL  = process.env.NODE_ENV === 'production'
    ? 'https://backend-formulario-cademonline-ba7zkvym3a-ue.a.run.app' // Produccion Cloud Run                               
    : 'http://localhost:5001'; // Desarrollo

export const API_BASE_URL_COMUNIDAD_CADEM  = process.env.NODE_ENV === 'production'
    ? 'https://backend-form-comunidad-cadem-pyxwmvpacq-ue.a.run.app' // Produccion Cloud Run                               
    : 'http://localhost:5000'; // Desarrollo

export const API_BASE_URL_ENCUESTAS_DESTACADAS  = process.env.NODE_ENV === 'production'
    ? 'https://backend-encuestas-destacadas-ba7zkvym3a-ue.a.run.app' // Produccion Cloud Run                               
    : 'http://localhost:5010'; // Desarrollo
