// import React from 'react';
import React, {useState, useEffect} from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import './formulario-registro.css';
import './agradecimiento.css';

const FormularioAgradecimiento = (props) => {

    const [origen, setOrigen] = useQueryParam('origen', StringParam);
    const [sid, setSid] = useQueryParam('sid', StringParam);
    const [token, setToken] = useQueryParam('token', StringParam);
    
    //Hook useEffect
    useEffect(() => {                 
        if(origen != 'cademonline' && origen && sid && token){
            setTimeout(() => {
                // window.location.href = `https://s5.cademonline.cl/limesurvey/index.php/${sid}?token=${token}`;
                window.location.href = `https://s5.cademonline.cl/limesurvey/index.php/${sid}?token=${token}`;
            }, 2500);
        }
    },[]);


    return (
        <section className="section-container">
            <div className="container vh-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="">
                        <h3 className="text-center text-white titulo-agradecimiento">
                            ¡Muchas gracias por inscribirte!
                        </h3>
                        <div className="text-white text-center contenido-agradecimiento">
                            <p className="mb-0 separado">Ya eres parte de Cadem Online,</p>
                            <p className="mb-0 separado">a partir de ahora te llegarán otras encuestas</p>
                            <p className="mb-0 separado">a tu correo que deberás responder para</p>
                            <p className="mb-0 separado font-weight-bold">participar por más premios.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );    
}

export default FormularioAgradecimiento;
