
// export default App;
import React, { Component } from 'react';
import { QueryParamProvider } from 'use-query-params';
import FormularioRegistro from './components/forms/formulario-registro';
import FormularioAgradecimiento from './components/forms/formulario-agradecimiento';

import './App.css';

// import Final from './components/Final/Final';
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {

    render() {

    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <Route path="/formulario-registro" component={FormularioRegistro} />
                <Route path="/agradecimiento" component={FormularioAgradecimiento} />                                                               
            </QueryParamProvider>
        </Router>

        );
    }
}

export default App;
